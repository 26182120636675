import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer footer-dark position-relative">
        <div className="footer-content">
          <div className="container">
            <div className="row">
              {/* <!-- Footer widget 1 --> */}
              <div className="col-md-8 mx-auto">
                <div className="widget text-center mt-5">
                  <Link to={"/"} className="footer-logo mb-3 d-block">
                    {/* <!-- SVG Logo Start --> */}
                    <img src="logob.png" alt="" />
                    {/* <!-- SVG Logo End --> */}
                  </Link>
                  <p className="mt-3 ">
                    We offer modern solutions for accelerating your business
                    growth.
                  </p>
                  {/* <!-- contact info --> */}
                  <ul className="p-0 list-inline">
                    <li className="list-inline-item me-4">
                      <i className="me-1 ti-map-alt"></i>
                      New York, Us.{" "}
                    </li>
                    <li className="list-inline-item me-4">
                      <i className="me-1 ti-headphone-alt"></i> (+1) 484 547
                      8850{" "}
                    </li>
                    <li className="list-inline-item me-4">
                      <i className="me-1 ti-email"></i>{" "}
                      <a href="mailto:info@leveluptek.com">
                        info@leveluptek.com
                      </a>{" "}
                    </li>
                  </ul>
                  <div className="divider my-3"></div>
                  {/* <!-- footer links --> */}
                  <div className="copyright-links my-2">
                    <ul className="list-inline">
                      <li className="list-inline-item ps-2">
                        <Link to="/" className="list-group-item-action">
                          Home
                        </Link>
                      </li>
                      <li className="list-inline-item ps-2">
                        <Link
                          to="/solutions"
                          className="list-group-item-action"
                        >
                          Solutions
                        </Link>
                      </li>
                      <li className="list-inline-item ps-2">
                        <Link to="/about-us" className="list-group-item-action">
                          About Us
                        </Link>
                      </li>
                      <li className="list-inline-item ps-2">
                        <Link to="/blog" className="list-group-item-action">
                          Blog
                        </Link>
                      </li>
                      <li className="list-inline-item ps-2">
                        <Link to="/contact" className="list-group-item-action">
                          Contact
                        </Link>
                      </li>
                      <li className="list-inline-item ps-2">
                        <Link to="/" className="list-group-item-action">
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="list-inline-item ps-2">
                        <Link to="/" className="list-group-item-action pe-0">
                          Use of terms
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- copyright text --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        {/* <!--footer copyright --> */}
        <div className="footer-copyright">
          <div className="container">
            <div className="d-md-flex justify-content-between align-items-center py-3 text-center text-md-start">
              {/* <!-- copyright text --> */}
              <div className="copyright-text">
                ©2022 All Rights Reserved by Level UP.
              </div>
              {/* <!-- copyright links--> */}

              <div className="form-group">
                <small className="d-block">Idioma</small>
                <select className="form-select">
                  <option value="en">English (Inglés)</option>
                  <option value="es">Español (Spanish)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
