import React from "react";
import HeroSolutionsHome from "../components/shared/Banner-hero/HeroSolutionsHome";
import CTA_4 from "../components/shared/Call-To-Action/CTA_4";

const Solutions = () => {
  return (
    <>
      <HeroSolutionsHome></HeroSolutionsHome>

      <section className="service">
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="title text-center">
                  <h3 className="h1">Core Services</h3>
                  <p>
                    With extensive expertise around server, storage, and
                    software products, our IT Solution experts help our clients
                    understand their specific IT needs and deliver customized
                    solutions that result in increased efficiency and lower cost
                    of ownership.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-panel"></i>
                </div>
                <h5>Solution Architecture</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-reload"></i>
                </div>
                <h5>Full Stack Development</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-comments"></i>
                </div>
                <h5>IT Operations and Help desk</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-layout-slider"></i>
                </div>
                <h5>Network and Infrastructure Engineering</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-cloud-up"></i>
                </div>
                <h5>Cloud Services</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-server"></i>
                </div>
                <h5>Database Administration</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-lock"></i>
                </div>
                <h5>Cybersecurity and Risk Management</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <div className="title text-center">
                <h5 className="h1">Industry Expertise</h5>
                <p>
                  With IT requirements evolving at a rapid pace, businesses need
                  lasting solutions to support a growing environment. Our IT
                  Consulting experts assist with all phases of data center
                  projects from design, implementation, migration, on-going
                  support and more.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-rocket"></i>
                </div>
                <h5>Start-ups</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-star"></i>
                </div>
                <h5>Hospitality and retail</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-money"></i>
                </div>
                <h5>Finance Services</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-briefcase"></i>
                </div>
                <h5>Legal Services</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-shield"></i>
                </div>
                <h5>Insurance</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-settings"></i>
                </div>
                <h5>Construction</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-flag-alt"></i>
                </div>
                <h5>Non-profits</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="feature-box h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="ti-support"></i>
                </div>
                <h5>Virtual Healt Care</h5>
                <p className="feature-box-desc"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA_4></CTA_4>
    </>
  );
};

export default Solutions;
