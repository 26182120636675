import React from "react";

const HeroError404Home = () => {
  return (
    <>
      <div className="bg-grad pattern-overlay-3 py-7">
        <div className="container">
          <div className="row all-text-white">
            <div className="col-md-12 d-md-flex justify-content-md-between align-items-center">
              <div>
                <h1 className="fw-bold">Error</h1>
                <h6 className="subtitle">
                  Searching for something? Try one of the links below.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroError404Home;
