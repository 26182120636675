import React from "react";

const HeroSolutionsHome = () => {
  return (
    <>
      <div className="bg-grad pattern-overlay-3 py-7">
        <div className="container">
          <div className="row all-text-white">
            <div className="col-md-12 d-md-flex justify-content-md-between align-items-center">
              <div>
                <h1 className="fw-bold">Solutions</h1>
                <h6 className="subtitle">
                  We offers experienced and comprehensive help for a wide range
                  of business needs, and can help you to work smarter.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSolutionsHome;
