import React from "react";
import { Link } from "react-router-dom";

const CTA_1 = () => {
  return (
    <section className="p5-4">
      <div className="container callta">
        <div className="d-block d-md-flex bg-grad p-4 p-sm-5 all-text-white rounded pattern-overlay-3">
          <div className="align-self-center text-center text-md-start ">
            <h5>
              See a variety of LevelUp services in action, learn how to deploy{" "}
              <br />
              resources and envision your plans for the cloud.
            </h5>
          </div>
          <div className="mt-3 mt-md-0 text-center text-md-end ms-md-auto align-self-center">
            <Link to="/solutions" className="btn btn-white mb-0">
              Explore our services
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA_1;
