import React, { useState, useEffect } from "react";
import api from "../utils/Api";
import { Link, useLocation, useParams } from "react-router-dom";

const BlogDetail = (props) => {
  // const location = useLocation();
  // console.log(location);
  // const { slug } = location.state;
  const { id } = useParams();
  console.log(id);

  const [blog, setBlog] = useState({});

  useEffect(() => {
    api.get(`api/blog/${id}`).then((res) => {
      console.log(res);
      setBlog(res.data);
    });
  }, [id]);

  const createBlog = () => {
    return { __html: blog.content };
  };

  const capitalizeFirstLetter = (word) => {
    if (word) return word.charAt(0).toUpperCase() + word.slice(1);
    return "";
  };

  return (
    <div className="container mt-3">
      <h1>{blog.title}</h1>
      <h5 className="text-muted mt-3">
        Category: {capitalizeFirstLetter(blog.category)}
      </h5>
      <h5>
        {blog.month} {blog.day}
      </h5>
      <div className="mt-5 mb-5" dangerouslySetInnerHTML={createBlog()}></div>

      <hr />
      <p className="lead mb-5">
        <Link to="/blog" className="font-weight-bold">
          Back to Blogs
        </Link>
      </p>
    </div>
  );
};

export default BlogDetail;
