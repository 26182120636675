import React from "react";
import { Link } from "react-router-dom";

const CTA_4 = () => {
  return (
    <section className="p5-4">
      <div className="container callta">
        <div className="d-block d-md-flex bg-grad p-4 p-sm-5 all-text-white rounded pattern-overlay-3">
          <div className="align-self-center text-center text-md-start ">
            <h5>For questions or queries, visit our contact center.</h5>
          </div>
          <div className="mt-3 mt-md-0 text-center text-md-end ms-md-auto align-self-center">
            <Link to="/contact" className="btn btn-white mb-0">
              Go!
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA_4;
