import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import HeroBlogHome from "./shared/Banner-hero/HeroBlogHome";

const BlogPost = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/blog/`
        );
        setBlogs(res.data);
      } catch (err) {}
    };

    fetchBlogs();
  }, []);

  const capitalizeFirstLetter = (word) => {
    if (word) return word.charAt(0).toUpperCase() + word.slice(1);
    return "";
  };

  const getBlogs = () => {
    let list = [];
    let result = [];

    blogs.map((blogPost) => {
      return list.push(
        <section className="blog-page pb-0  mt-0">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-8">
                <div className="post-item post-bb">
                  <div className="post-item-wrap">
                    <div className="post-item-desc">
                      <strong className="d-inline-block mb-2 text-primary">
                        {capitalizeFirstLetter(blogPost.category)}
                      </strong>

                      <h3 className="mb-1">{blogPost.title}</h3>
                      <div className="mb-2 text-muted">
                        {blogPost.month} {blogPost.day}
                      </div>
                      <p className="card-text mb-2">{blogPost.excerpt}</p>

                      <Link
                        to={`/blog/${blogPost.slug}`}
                        className="item-link stretched-link"
                      >
                        Continue reading<i className="ti-minus"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    });

    for (let i = 0; i < list.length; i += 2) {
      result.push(
        <div key={i}>
          <div>{list[i]}</div>
          <div>{list[i + 1] ? list[i + 1] : null}</div>
        </div>
      );
    }

    return result;
  };

  return (
    <>
      <HeroBlogHome></HeroBlogHome>

      {getBlogs()}
    </>
  );
};

export default BlogPost;
