import React from "react";

const HeroHome = () => {
  return (
    <>
      <section className="p-0 py-5 pattern-overlay-2">
        <div className="container">
          <div className="row py-0 py-md-5 justify-content-between align-items-center">
            <div className="col-sm-10 col-lg-6  my-5 mt-md-0 position-relative">
              <div className="text-start py-0 py-md-5 my-5">
                <h5 className="text-home">You found it!</h5>
                <h2 className="display-4 fw-normal text-home">
                  Expect nothing less than perfect.
                </h2>
                <h5 className="mb-4">
                  We offer modern solutions for accelerating your business
                  growth.
                </h5>
                <a href="#home-services" className="btn btn-grad">
                  Get Started!
                </a>
              </div>
            </div>
            <div className="col-sm-10 col-md-6 d-none d-lg-block mb-5 position-relative">
              <img src="assets/images/banner/Startup.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroHome;
