import React from "react";
import { Link } from "react-router-dom";
import HeroError404Home from "../components/shared/Banner-hero/HeroError404Home";

const Error = () => {
  return (
    <>
      <HeroError404Home></HeroError404Home>
      <section className="error-page">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <p className="display-1 fw-bold text-grad">
                <i class="ti-location-arrow"></i>
              </p>
              <p className="mb-4 h1">Page Not Found</p>
              <p>Ooops! The page you are looking for, couldn't be found.</p>
              <Link to="/" className="btn btn-grad">
                Back to home!
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error;
