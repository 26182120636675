import React from "react";
import { Link } from "react-router-dom";
import AlertHome from "./Call-To-Action/AlertHome";

const Navbar = () => {
  return (
    <header className="header-static navbar-sticky navbar-light">
      {/* Alert */}

      <AlertHome></AlertHome>

      {/* alert  */}
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          {/* <!-- Logo --> */}
          <Link to="/" className="navbar-brand">
            {/* <!-- Logo --> */}
            <img className="navbar-brand-item" src="logo4.png" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav navbar-nav-scroll navbar-nav-scroll ms-auto">
              <li className="nav-item">
                <Link to="/solutions" className="nav-link">
                  Solutions
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about-us" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
