import React from "react";

const HeroBlogHome = () => {
  return (
    <>
      <div className="bg-grad pattern-overlay-3 py-7">
        <div className="container">
          <div className="row all-text-white">
            <div className="col-md-12 d-md-flex justify-content-md-between align-items-center">
              <div>
                <h1 className="fw-bold">Tech Bits</h1>
                <h6 className="subtitle">
                  Check out some of our latest blog post.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container mt-2">
        <div className="nav-scroller">
          <nav className="nav d-flex justify-content-between">
            <Link className="p-2 text muted" to="/category/business">
              <h6 className="">Bussiness</h6>
            </Link>
            <Link className="p-2 text muted" to="/category/culture">
              <h6 className=""> Culture </h6>
            </Link>
            <Link className="p-2 text muted" to="/category/ideas">
              <h6 className="">Ideas</h6>
            </Link>
            <Link className="p-2 text muted" to="/category/siencie">
              <h6 className="">Sience </h6>
            </Link>
            <Link className="p-2 text muted" to="/category/security">
              <h6 className="">Security </h6>
            </Link>
            <Link className="p-2 text muted" to="/category/technology">
              <h6 className="">Technology</h6>
            </Link>
          </nav>
        </div>
      </div> */}
    </>
  );
};

export default HeroBlogHome;
