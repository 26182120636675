import React from "react";

const AlertHome = () => {
  return (
    <div className="bg-grad mb-3 all-text-white" role="alert">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-transparent alert-dismissible fade show mb-0"
              role="alert"
            >
              <strong>Howdy!</strong> Follow us on Instagram!{" "}
              <strong>
                <a
                  href="https://www.instagram.com/levelup.tek/?hl=es"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-alert"
                >
                  @levelup.tek{" "}
                </a>
              </strong>
              | We post new photos, news and interesting facts every day.
              <button
                type="button"
                className="btn-close mb-0"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertHome;
