import React from "react";
import HeroAboutHome from "../components/shared/Banner-hero/HeroAboutHome";
import CTA_3 from "../components/shared/Call-To-Action/CTA_3";

const About = () => {
  return (
    <>
      <HeroAboutHome></HeroAboutHome>
      <section>
        <div className="container h-100">
          <div className="row">
            {/* <!-- left --> */}
            <div className="col-md-10 col-lg-7 align-self-center ">
              <img
                className="rounded"
                src="assets/images/banner/Dataanalyse.svg"
                alt="img"
              />
            </div>
            {/* <!-- right --> */}
            <div className="col-md-12 col-lg-5 align-self-center mt-4 mt-lg-0">
              <div className="title text-start pb-0">
                <h5 className="text-home">Who we are</h5>
                <h3 className="h1">Tech for people unlocks the future.</h3>
                <p>
                  At Level UP Technology, we believe that technology with strong
                  human values ​​can actively drive change for the better. Find
                  out how Tech for People opens up the future, creating a
                  positive impact on people and the world around us.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- row end --> */}
        </div>
      </section>
      <CTA_3></CTA_3>
    </>
  );
};

export default About;
