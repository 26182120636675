import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/shared/Layout";
import Home from "./pages/Home";
import Category from "./components/Category";
import BlogDetail from "./components/BlogDetail";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Solutions from "./pages/Solutions";
import About from "./pages/About";
import BlogPost from "./components/BlogPost";
import Maintenance from "./pages/Maintenance";

function App() {
  const maintenance = false;
  return (
    <BrowserRouter>
      <Routes>
        {maintenance ? (
          <Route path="/" element={<Maintenance />} />
        ) : (
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />}></Route>
          </Route>
        )}

        <Route path="/" element={<Layout />}>
          <Route path="*" element={<Error />} />
        </Route>

        <Route path="/" element={<Layout />}>
          <Route exact path="/blog" element={<BlogPost />} />
          <Route exact path="/category/:id" element={<Category />} />
          <Route exact path="/blog/:id" element={<BlogDetail />} />
        </Route>

        <Route path="/contact" element={<Layout />}>
          <Route index element={<Contact />}></Route>
        </Route>

        <Route path="/contact" element={<Layout />}>
          <Route index element={<Contact />}></Route>
        </Route>
        <Route path="/solutions" element={<Layout />}>
          <Route index element={<Solutions />}></Route>
        </Route>
        <Route path="/about-us" element={<Layout />}>
          <Route index element={<About />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
