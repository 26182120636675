import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = state;

  const submitData = async (e) => {
    e.preventDefault();

    if (name && email && subject && message) {
      const res = fetch(
        "https://hidden-analyzer-344417-default-rtdb.firebaseio.com/FormContact.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            subject,
            message,
          }),
        }
      );

      if (res) {
        setState({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        toast.success("Form Submitted Successfully");
      } else {
        toast.error("Please provide value in each input field");
      }
    } else {
      toast.error("Please provide value in each input field");
    }
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <>
      <ToastContainer position="top-center" />
      <form
        className="contact-form needs-validation"
        id="contact-form"
        name="contactform"
        method="POST"
      >
        {/* <!-- Main form --> */}
        <div className="row">
          <div className="col-md-6">
            {/* <!-- name --> */}
            <div className="mb-3 position-relative">
              <input
                id="con-name"
                name="name"
                type="text"
                className="form-control"
                placeholder="Name"
                value={name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            {/* <!-- email --> */}
            <div className="mb-3 position-relative">
              <input
                required
                id="con-email"
                name="email"
                type="email"
                className="form-control"
                placeholder="E-mail"
                value={email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            {/* <!-- Subject --> */}
            <div className="mb-3 position-relative">
              <input
                id="con-subject"
                name="subject"
                type="text"
                className="form-control"
                placeholder="Subject"
                value={subject}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            {/* <!-- Message --> */}
            <div className="mb-3 position-relative">
              <textarea
                id="con-message"
                name="message"
                cols="40"
                rows="6"
                className="form-control"
                placeholder="Message"
                value={message}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          {/* <!-- submit button --> */}
          <div className="col-md-12 d-grid">
            <button
              className="btn btn-grad m-0"
              type="submit"
              onClick={submitData}
            >
              Send Message
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
