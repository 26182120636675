import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BlogNews = () => {
  const [featuredBlog, setFeaturedBlog] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/blog/featured`
        );
        setFeaturedBlog(res.data[0]);
        console.log(res.data);
      } catch (err) {}
    };

    fetchData();
  }, []);

  const capitalizeFirstLetter = (word) => {
    if (word) return word.charAt(0).toUpperCase() + word.slice(1);
    return "";
  };

  const getBlogs = () => {
    let list = [];
    let result = [];

    for (let i = 0; i < list.length; i += 2) {
      result.push(
        <div key={i}>
          <div>{list[i]}</div>
          <div>{list[i + 1] ? list[i + 1] : null}</div>
        </div>
      );
    }

    return result;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="tiny-slider arrow-dark arrow-hover">
            <div
              className="tiny-slider-inner"
              data-arrow="true"
              data-dots="false"
              data-items-xl="3"
              data-items-lg="3"
              data-items-md="2"
              data-items-sm="2"
              data-items-xs="1"
              data-autoplay="0"
              data-pause="false"
            >
              <div className="item">
                <div className="post">
                  <div className="post-info">
                    <span className="post-tag bg-grad text-white mb-3 clearfix">
                      {capitalizeFirstLetter(featuredBlog.category)}
                    </span>
                    <div className="post-time">
                      {featuredBlog.month} {featuredBlog.day}
                    </div>
                    <Link
                      to={`/blog/${featuredBlog.slug}`}
                      className="post-title"
                    >
                      {featuredBlog.title}
                    </Link>
                    <p className="mb-0">{featuredBlog.excerpt}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {getBlogs()}
    </>
  );
};

export default BlogNews;
