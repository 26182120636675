import React from "react";
import HeroContactHome from "../components/shared/Banner-hero/HeroContactHome";
import CTA_1 from "../components/shared/Call-To-Action/CTA_1";
import ContactForm from "../components/shared/Forms/ContactForm";

const Contact = () => {
  return (
    <>
      <HeroContactHome></HeroContactHome>
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <div className="title text-center">
                <h3 className="h1">How can we assist you?</h3>
                <p>
                  Level UP customer support is available free of charge.
                  Connection charges can vary when calling from outside the
                  area, abroad or from a mobile phone.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-md-6">
              <div className="h-100">
                {/* Form */}
                <ContactForm></ContactForm>
                {/* Form */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container ">
          <div className="row justify-content-md-center">
            <div className="col-md-4 text-center">
              <h5 className="mb-1">
                <i className="me-1 ti-email"></i> General communication
              </h5>
              <p>
                For general queries, including partnership opportunities, please
                email{" "}
                <a href="mailto:info@leveluptek.com">info@leveluptek.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <CTA_1></CTA_1>
    </>
  );
};

export default Contact;
