import React from "react";
import { Link } from "react-router-dom";

const CTA_3 = () => {
  return (
    <section className="p5-4">
      <div className="container callta">
        <div className="d-block d-md-flex bg-grad p-4 p-sm-5 all-text-white rounded pattern-overlay-3">
          <div className="align-self-center text-center text-md-start ">
            <h5>The best time for change is now.</h5>
            <p>
              We provide digital transformation solutions, enabling companies to
              make their visions reality.{" "}
            </p>
          </div>
          <div className="mt-3 mt-md-0 text-center text-md-end ms-md-auto align-self-center">
            <Link to="/solutions" className="btn btn-white mb-0">
              Lear more...
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA_3;
