import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const submitData = async (e) => {
    e.preventDefault();

    if (email) {
      const res = fetch(
        "https://hidden-analyzer-344417-default-rtdb.firebaseio.com/emailNewsletter.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        }
      );

      if (res) {
        setEmail({
          email: "",
        });
        toast.success("Form Submitted Successfully");
      } else {
        toast.error("Please provide value in each input field");
      }
    } else {
      toast.error("Please provide value in each input field");
    }
    setEmail("");
  };
  return (
    <>
      <ToastContainer position="top-center" />
      <form method="POST" id="newsletter-form" name="newsletterform">
        <div className="input-group px-0 px-md-5">
          <input
            className="form-control text-muted border-end-0 rounded-start bg-transparent text-white mb-0"
            placeholder="Enter your email..."
            id="con-email"
            required
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            onClick={submitData}
            className="btn btn-grad m-0"
          >
            <span className="d-none d-md-block">
              <i className="far fa-paper-plane"></i>
            </span>
            <span className="d-md-none">
              <i className="far fa-paper-plane"></i>
            </span>
          </button>
        </div>
      </form>
    </>
  );
};

export default Newsletter;
