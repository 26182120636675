import React from "react";
import { Link } from "react-router-dom";
import HeroHome from "../components/shared/Banner-hero/HeroHome";
import BlogNews from "../components/shared/BlogNews";
import CTA_2 from "../components/shared/Call-To-Action/CTA_2";
import Newsletter from "../components/shared/Forms/Newsletter";

const Home = () => {
  return (
    <>
      {/* HEROHOME */}
      <HeroHome></HeroHome>
      {/* HEROHOME */}

      <section className="pb-3" id="home-services">
        <div className="container">
          <div className="row">
            {/* <!-- column 1 --> */}
            <div className="col-md-4">
              {/* <!-- title --> */}
              <h5 className="text-home">Our services</h5>
              <h3 className="h1">
                Have a look at the professional services Level UP offers, and
                let's talk.
              </h3>
              <p>
                We offers experienced and comprehensive help for a wide range of
                business needs, and can help you to work smarter.
              </p>
              {/* <!-- service item --> */}
              <div className="bg-light p-4 p-lg-5 ">
                <span className="display-5 icon-primary">
                  <i className="ti-server"></i>
                </span>
                <h5>Solutions Architecture</h5>
                <p className="mb-0">
                  Develop a business API in a regulated and flexible way,
                  regardless of the degree of maturity of the organization in
                  this environment.
                </p>
              </div>
            </div>

            {/* <!-- column 2 --> */}
            <div className="col-md-4 mt-5">
              {/* <!-- service item --> */}
              <div className="bg-light p-4 p-lg-5 mb-5">
                <span className="display-5 icon-primary">
                  <i className="ti-briefcase"></i>
                </span>
                <h5>Legal Services</h5>
                <p className="mb-0">
                  It’s important to have a legal partner who understands all
                  aspects of your business and embraces technology to help you
                  move ahead effectively and decisively.
                </p>
              </div>
              {/* <!-- service item --> */}
              <div className="bg-light p-4 p-lg-5 mb-5">
                <span className="display-5 icon-primary">
                  <i className="ti-wallet"></i>
                </span>
                <h5>Financial Services</h5>
                <p className="mb-0">
                  We can help you stay focused on the future of financial
                  services - one that is stronger, fairer and more sustainable.
                </p>
              </div>
            </div>

            {/* <!-- column 3 --> */}
            <div className="col-md-4">
              {/* <!-- service item --> */}
              <div className="bg-grad all-text-white p-4 p-lg-5 mb-5">
                <span className="display-5">
                  <i className="ti-world icon-white"></i>
                </span>
                <h5>Ultra Performance</h5>
                <p className="mb-0">
                  We provide digital transformation solutions. With us at your
                  side, you will find the IT solution to achieve your strategic
                  and financial goals.
                </p>
              </div>
              {/* <!-- service item --> */}
              <div className="bg-light p-4 p-lg-5 mb-5">
                <span className="display-5 icon-primary">
                  <i className="ti-crown"></i>
                </span>
                <h5>Premium Support</h5>
                <p className="mb-0">
                  We have a strong foundation built on legacy and emerging
                  technologies, including excellent track record of on-time
                  deliveries. We are emerging as one of the most promising
                  private talent sourcing and management firms in the world.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-2 mt-md-4">
              <div className="text-center">
                <Link to="/solutions" className="btn btn btn-grad mt-4">
                  See All Services
                </Link>
                <small className="d-block mt-2">
                  Gain Access to a Network of Top Industry Experts
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CALLTOACTION */}

      <CTA_2></CTA_2>

      {/* CALLTOACTION */}

      <section>
        <div className="container h-100">
          <div className="row">
            {/* <!-- left --> */}
            <div className="col-md-10 col-lg-7 align-self-center ">
              <img
                className="rounded"
                src="assets/images/banner/Finance.svg"
                alt="img"
              />
            </div>
            {/* <!-- right --> */}
            <div className="col-md-12 col-lg-5 align-self-center mt-4 mt-lg-0">
              <div className="title text-start pb-0">
                <h5 className="text-home">Who we are</h5>
                <h3 className="h1">Accelerating the change.</h3>
                <p>
                  We inspire and provide new solutions to the teams with which
                  we co-create and work. We help to adopt and operate new
                  technologies that provide solutions to new challenges and be
                  able to have the firmness for future challenges. We accompany
                  our clients in their change and cyber protecting their
                  investments.We are Level UP.
                </p>
                <Link to="/about-us" className="btn btn btn-grad">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- row end --> */}
        </div>
      </section>

      <section className="blog">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <div className="title text-center">
                <h3 className="h1">Read our news, comments & thoughts</h3>
                <p className="mb-0">
                  Our blog shows our hard work and devotion to helping our
                  clients succeed. Don't just take our word for it. Check out
                  the latest work.
                </p>
              </div>
            </div>
          </div>

          {/* BlogPost */}

          <BlogNews></BlogNews>

          {/* BlogPost */}
        </div>
      </section>

      {/* Newsletter */}

      <section className="bg-light">
        <div className="container">
          <div className="col-md-12 col-lg-9 mx-auto p-4 p-sm-5 position-relative">
            <div className="text-center px-0 px-sm-5">
              <h3 className="h1">Stories in your inbox.</h3>
              <p>
                Join our weekly newsletter and learn more about the services
                that Level Up has for you.
              </p>
              {/* Form  */}
              <Newsletter></Newsletter>
              {/* Form  */}
              <small className="mt-3 d-block">
                *We respect your privacy and do not tolerate spam.
              </small>
            </div>
          </div>
        </div>
      </section>

      {/* Newsletter */}
    </>
  );
};

export default Home;
